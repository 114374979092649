import React, { createContext, useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router";
import api from "../service/api";

const Context = createContext();

function AuthProvider({ children }) {
    const [authenticated, setAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);
    const [errorLogin, setErrorLogin] = useState("");
    const [userRole, setUserRole] = useState(null);

    const navigate = useNavigate();

    const handleClickLogout = useCallback(() => {
        setAuthenticated(false);
        setUserRole(null);
        localStorage.removeItem("email");
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("role");
        api.defaults.headers.Authorization = undefined;
        navigate("/login");
    }, [navigate]);

    const isTokenExpired = useCallback((token) => {
        try {
            const payload = JSON.parse(atob(token.split(".")[1])); // Decodifica o payload do JWT
            const currentTime = Math.floor(Date.now() / 1000); // Tempo atual em segundos
            return payload.exp < currentTime; // Retorna true se expirado
        } catch (error) {
            console.error("Erro ao verificar token:", error);
            return true; // Se não puder decodificar, considere expirado
        }
    }, []);

    const handleTokenExpiration = useCallback(async () => {
        try {
            const refreshToken = localStorage.getItem("refreshToken");
            if (!refreshToken) {
                console.error("Refresh token ausente. Deslogando usuário.");
                handleClickLogout();
                return;
            }

            const response = await api.post("/refresh", { refreshToken });
            const newToken = response.data.token;

            localStorage.setItem("token", newToken); // Armazena o novo token como string
            api.defaults.headers.Authorization = `Bearer ${newToken}`;
            setAuthenticated(true);
        } catch (error) {
            console.error("Erro ao renovar o token:", error);
            handleClickLogout();
        }
    }, [handleClickLogout]);

    useEffect(() => {
        const token = localStorage.getItem("token");
        const role = localStorage.getItem("role");
        if (token) {
            if (isTokenExpired(token)) {
                console.error("Token expirado. Tentando renovar...");
                handleTokenExpiration();
            } else {
                api.defaults.headers.Authorization = `Bearer ${token}`;
                setAuthenticated(true);
                setUserRole(role);
            }
        } else {
            setAuthenticated(false);
        }
        setLoading(false);

        const interceptorRequest = api.interceptors.request.use((config) => {
            const token = localStorage.getItem("token");

            if (config.url.includes("/perguntas")) {
                return config;
            }

            if (token && !isTokenExpired(token)) {
                config.headers.Authorization = `Bearer ${token}`;
            } else {
                console.error("Token expirado. Tentando renovar...");
                handleTokenExpiration();
            }
            return config;
        });

        const interceptorResponse = api.interceptors.response.use(
            (response) => response,
            async (error) => {
                if (error.response?.status === 401) {
                    console.error("Erro 401: Tentando renovar o token...");
                    handleTokenExpiration();
                }
                return Promise.reject(error);
            }
        );

        return () => {
            api.interceptors.request.eject(interceptorRequest);
            api.interceptors.response.eject(interceptorResponse);
        };
    }, [handleClickLogout, isTokenExpired, handleTokenExpiration]);

    async function handleClickLogin(values) {
        api
            .post("login", {
                email: values.email,
                senha: values.senha,
            })
            .then((response) => {
                const token = response.data.token;
                const refreshToken = response.data.refreshToken;
                const email = response.data.email;
                const role = response.data.role;

                localStorage.setItem("token", token); // Armazena como string
                localStorage.setItem("refreshToken", refreshToken);
                localStorage.setItem("email", email);
                localStorage.setItem("role", role);

                api.defaults.headers.Authorization = `Bearer ${token}`;
                setAuthenticated(true);
                setUserRole(role);
                navigate("/home", { replace: true });
            })
            .catch((error) => {
                if (error.response) {
                    setErrorLogin(error.response.status);
                }
            });
    }

    return (
        <Context.Provider
            value={{
                authenticated,
                loading,
                errorLogin,
                userRole,
                handleClickLogin,
                handleClickLogout,
            }}
        >
            {children}
        </Context.Provider>
    );
}

export { Context, AuthProvider };
