import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import api from '../../service/api';
import styles from './index.module.css';
import NavbarComponent from '../../components/Navbar/NavbarComponent';
import NavMenuComponent from '../../components/NavMenu/NavMenuComponent';
import { Row, Table, Input, Button, InputGroup, Col, InputGroupText } from 'reactstrap';
import { AiOutlineSearch, AiOutlineEye } from 'react-icons/ai';

function Parceiro() {
    const [parceiros, setParceiros] = useState([]);
    const [parceirosFiltrados, setParceirosFiltrados] = useState([]);
    const [statusParceiro, setStatusParceiro] = useState("ativo");
    const [busca, setBusca] = useState("");

    useEffect(() => {
        api.get('parceiro').then(({ data }) => {
            const dataSort = data.sort((a, b) => 
                a.parceiro_nome < b.parceiro_nome ? -1 : (a.parceiro_nome > b.parceiro_nome ? 1 : 0)
            );
            setParceiros(dataSort);
            setParceirosFiltrados(dataSort);
        }).catch(error => console.error("Erro ao buscar parceiros:", error));
    }, []);

    useEffect(() => {
        const lowerBusca = busca.toLocaleLowerCase();
        const filtrados = parceiros.filter(parceiro =>
            parceiro.parceiro_nome && parceiro.parceiro_nome.toLocaleLowerCase().includes(lowerBusca)
        );
        setParceirosFiltrados(busca ? filtrados : parceiros);
    }, [busca, parceiros]);

    return (
        <div className={styles.mainDiv}>
            <NavbarComponent />
            <NavMenuComponent />
            <div className={styles.route}>
                Home &gt; Lista de Parceiros
            </div>
            <div className={styles.title}>
                Lista de Parceiros
            </div>
            <div className={styles.container}>
                <div className={styles.divInput}>
                    <InputGroup>
                        <Row>
                            <Col xs="6">
                                <InputGroup>
                                    <Input
                                        size="sm"
                                        placeholder="Nome"
                                        className={styles.inputBusca}
                                        type="text"
                                        value={busca}
                                        onChange={(ev) => setBusca(ev.target.value)}
                                    />
                                    <InputGroupText className={styles.textBuscar}>
                                        <AiOutlineSearch size={17} className={styles.iconBuscar} />
                                        Buscar
                                    </InputGroupText>
                                </InputGroup>
                            </Col>
                            <Col xs="6">
                                <div className={styles.selectFiltro}>
                                    <InputGroup>
                                        <Input
                                            value={statusParceiro}
                                            onChange={(ev) => setStatusParceiro(ev.target.value)}
                                            name="select"
                                            type="select"
                                            size="sm"
                                            className={styles.selectFiltro}
                                        >
                                            <option value="ativo">Ativo</option>
                                            <option value="inativo">Inativo</option>
                                        </Input>
                                        <InputGroupText className={styles.textFiltro}>Filtro</InputGroupText>
                                    </InputGroup>
                                </div>
                            </Col>
                        </Row>
                    </InputGroup>
                </div>
                <Table size="sm" striped className={styles.table}>
                    <thead>
                        <tr>
                            <th>Parceiro</th>
                            <th>Especialidade</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {parceirosFiltrados
                            .filter(parceiro => parceiro.ativo === (statusParceiro === "ativo"))
                            .map((parceiro) => (
                                <tr key={parceiro.parceiro_id}>
                                    <td className={styles.tbody}>{parceiro.parceiro_nome}</td>
                                    <td className={styles.tbody}>{parceiro.especialidade || "Empresa"}</td>
                                    <td>
                                        <Link to={`/visualizarParceiro/${parceiro.parceiro_id}`}>
                                            <Button size="sm" className={styles.buttonPerfil}>
                                                <AiOutlineEye size={17} className={styles.buttonIcon} />
                                                Ver perfil
                                            </Button>
                                        </Link>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </Table>
            </div>
        </div>
    );
}

export default Parceiro;
