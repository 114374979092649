import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import api from '../../service/api';
import styles from './index.module.css';
import NavbarComponent from '../../components/Navbar/NavbarComponent';
import NavMenuComponent from '../../components/NavMenu/NavMenuComponent';
import { Row, Col, Button } from "reactstrap";
import { MdOutlineModeEditOutline } from "react-icons/md";

function VisualizarAuxiliar() {
    const { id } = useParams();

    const [auxiliar, setAuxiliar] = useState(null);

    useEffect(() => {
        api.get(`auxiliar/${id}`).then(({ data }) => {
            setAuxiliar(data);
        });
    }, [id]);

    if (!auxiliar) return <div>Carregando...</div>;

    return (
        <div className={styles.mainDiv}>
            <NavbarComponent />
            <NavMenuComponent />

            <div className={styles.route}>
                Home &gt; Lista de Auxiliar &gt; Perfil Auxiliar
            </div>
            <div className={styles.title}>
                Perfil Auxiliar
            </div>
            <div className={styles.divButton}>
                <Link to={`/editarAuxiliar/${auxiliar.id}`}>
                    <Button size="sm" className={styles.buttonEditar}>
                        <MdOutlineModeEditOutline size={17} className={styles.buttonIcon} />Editar
                    </Button>
                </Link>
            </div>
            <div responsive className={styles.container}>
                <div className={styles.divDadosGerais}>
                    Dados Gerais
                </div>
                <div className={styles.linha} />
                <div className={styles.divDadosAuxiliar}>
                    <Row xs="3">
                        <Col>
                            Nome <p className={styles.p}>{auxiliar.nome}</p>
                        </Col>
                        <Col>
                            Nome Social <p className={styles.p}>{auxiliar.nome_social}</p>
                        </Col>
                        <Col>
                            Data de Nascimento <p className={styles.p}>{auxiliar.data_nascimento}</p>
                        </Col>
                        <Col>
                            Gênero <p className={styles.p}>{auxiliar.genero}</p>
                        </Col>
                        <Col>
                            CPF <p className={styles.p}>{auxiliar.cpf}</p>
                        </Col>
                    </Row>
                </div>
                <div className={styles.divContato}>
                    Contato
                </div>
                <div className={styles.linha} />
                <div className={styles.divContatoAuxiliar}>
                    <Row>
                        <Col xs="6">
                            Telefone <p className={styles.p}>{auxiliar.telefone}</p>
                        </Col>
                        <Col xs="6">
                            E-mail <p className={styles.p}>{auxiliar.email}</p>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
}

export default VisualizarAuxiliar;
