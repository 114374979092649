import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import api from '../../service/api';
import NavbarComponent from '../../components/Navbar/NavbarComponent';
import NavMenuComponent from '../../components/NavMenu/NavMenuComponent';
import ModalQuestionariosComponent from '../../components/ModalQuestionarios/ModalQuestionariosComponent';
import styles from './index.module.css';
import { Row, Col, Table, Input, Button, InputGroup, InputGroupText } from 'reactstrap';
import { AiOutlineSearch, AiOutlineEye, AiOutlineArrowRight } from 'react-icons/ai';

function Vidas() {
    const [vidas, setVidas] = useState([]);
    const [vidasFiltradas, setVidasFiltradas] = useState([]);
    const [statusVida, setStatusVida] = useState("ativo");
    const [busca, setBusca] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [vida, setVida] = useState("");
    const [vidaTelefone, setVidaTelefone] = useState("");

    useEffect(() => {
        api.get('vida').then(({ data }) => {
            const dataSort = data.sort((a, b) => 
                a.vida_nome.localeCompare(b.vida_nome)
            );
            setVidas(dataSort);
            setVidasFiltradas(dataSort);
        }).catch(error => console.error("Erro ao buscar vidas:", error));
    }, []);

    useEffect(() => {
        const lowerBusca = busca.toLowerCase();
        const filtrados = vidas.filter(vida => 
            vida.vida_nome && vida.vida_nome.toLowerCase().includes(lowerBusca)
        );
        setVidasFiltradas(busca ? filtrados : vidas);
    }, [busca, vidas]);

    const enviarQuestionario = (id, telefone) => {
        setVida(id);
        setVidaTelefone(telefone);
        setIsOpen(true);
    };

    return (
        <div className={styles.mainDiv}>
            <NavbarComponent />
            <NavMenuComponent />
            {isOpen && <ModalQuestionariosComponent vida={vida} telefone={vidaTelefone} />}

            <div className={styles.route}>Home &gt; Lista de Vidas</div>
            <div className={styles.title}>Lista de Vidas</div>
            <div className={styles.container}>
                <div className={styles.divInput}>
                    <InputGroup>
                        <Row>
                            <Col xs="6">
                                <InputGroup>
                                    <Input
                                        size="sm"
                                        placeholder="Nome"
                                        className={styles.inputBusca}
                                        type="text"
                                        value={busca}
                                        onChange={(ev) => setBusca(ev.target.value)}
                                    />
                                    <InputGroupText className={styles.textBuscar}>
                                        <AiOutlineSearch size={17} className={styles.iconBuscar} />
                                        Buscar
                                    </InputGroupText>
                                </InputGroup>
                            </Col>
                            <Col xs="6">
                                <div className={styles.selectFiltro}>
                                    <InputGroup>
                                        <Input
                                            value={statusVida}
                                            onChange={(ev) => setStatusVida(ev.target.value)}
                                            name="select"
                                            type="select"
                                            size="sm"
                                            className={styles.selectFiltro}
                                        >
                                            <option value="ativo">Ativo</option>
                                            <option value="inativo">Inativo</option>
                                        </Input>
                                        <InputGroupText className={styles.textFiltro}>Filtro</InputGroupText>
                                    </InputGroup>
                                </div>
                            </Col>
                        </Row>
                    </InputGroup>
                </div>
                <Table size="sm" striped className={styles.table}>
                    <thead>
                        <tr>
                            <th>Vida</th>
                            <th>Data de Nascimento</th>
                            <th>CPF</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {vidasFiltradas
                            .filter(vida => vida.usuario_ativo === (statusVida === "ativo"))
                            .map(vida => (
                                <tr key={vida.vida_id}>
                                    <td className={styles.tbody}>{vida.vida_nome}</td>
                                    <td className={styles.tbody}>{vida.data_nascimento}</td>
                                    <td className={styles.tbody}>{vida.cpf}</td>
                                    <td>
                                        <Link to={`/visualizarVida/${vida.vida_id}`}>
                                            <Button size="sm" className={styles.buttonPerfil}>
                                                <AiOutlineEye size={17} className={styles.buttonIcon} />
                                                Ver perfil
                                            </Button>
                                        </Link>
                                    </td>
                                    <td>
                                        <Button
                                            size="sm"
                                            onClick={() => enviarQuestionario(vida.vida_id, vida.telefone)}
                                            className={styles.buttonFormulario}
                                        >
                                            <AiOutlineArrowRight size={17} className={styles.buttonIcon} />
                                            Enviar Formulário
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </Table>
            </div>
        </div>
    );
}

export default Vidas;
