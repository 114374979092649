import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// import { useNavigate } from "react-router";
import api from '../../service/api';
import styles from './index.module.css';
import NavbarComponent from '../../components/Navbar/NavbarComponent';
import NavMenuComponent from '../../components/NavMenu/NavMenuComponent';
import { Row, Table, Input, Button, InputGroup, Col, InputGroupText } from 'reactstrap';
import { AiOutlineSearch, AiOutlineEye } from 'react-icons/ai';

function Auxiliares() {
    
    // const navigate = useNavigate();

    const [auxiliares, setAuxiliares] = useState([]);
    const [auxiliaresFiltradas, setAuxiliaresFiltradas] = useState([]);
    const [statusAuxiliar, setStatusAuxiliar] = useState("ativo");
    const [busca, setBusca] = useState("");

    useEffect(() => {
        api.get('auxiliar').then(({ data }) => {
            const dataSort = data.sort((obj1, obj2) =>
                obj1.auxiliar_nome < obj2.auxiliar_nome ? -1 : (obj1.auxiliar_nome > obj2.auxiliar_nome ? 1 : 0)
            );
            setAuxiliares(dataSort);
            setAuxiliaresFiltradas(dataSort);
        });
    }, []);
    
    // TODO: o que faz essa função?
    // async function deleteAuxiliar(id) {
    //     api
    //         .delete("auxiliar/" + id, {
    //             id: id
    //         })
    //         .then(() => {
    //             alert("Auxiliar inativado!");
    //             navigate("/auxiliares", { replace: true });
    //         });
    // }


    useEffect(() => {
        const lowerBusca = busca.toLocaleLowerCase();
        const filtrados = auxiliares.filter((auxiliar) =>
            auxiliar.auxiliar_nome && auxiliar.auxiliar_nome.toLocaleLowerCase().includes(lowerBusca)
        );
        setAuxiliaresFiltradas(busca ? filtrados : auxiliares);
    }, [auxiliares, busca]);

    return (
        <div className={styles.mainDiv}>
            <NavbarComponent />
            <NavMenuComponent />
            <div className={styles.route}>
                Home &gt; Lista de Auxiliares
            </div>
            <div className={styles.title}>
                Lista de Auxiliares
            </div>
            <div responsive className={styles.container}>
                <div className={styles.divInput}>
                    <InputGroup>
                        <Row>
                            <Col xs="6">
                                <InputGroup>
                                    <Input
                                        size="sm"
                                        placeholder="Nome"
                                        className={styles.inputBusca}
                                        type="text"
                                        value={busca}
                                        onChange={(ev) => setBusca(ev.target.value)}
                                    />
                                    <InputGroupText className={styles.textBuscar}>
                                        <AiOutlineSearch size={17} className={styles.iconBuscar} />
                                        Buscar
                                    </InputGroupText>
                                </InputGroup>
                            </Col>
                            <Col xs="6">
                                <div className={styles.selectFiltro}>
                                    <InputGroup>
                                        <Input
                                            value={statusAuxiliar}
                                            onChange={(ev) => setStatusAuxiliar(ev.target.value)}
                                            name="select"
                                            type="select"
                                            size="sm"
                                            className={styles.selectFiltro}
                                        >
                                            <option value="ativo">Ativo</option>
                                            <option value="inativo">Inativo</option>
                                        </Input>
                                        <InputGroupText className={styles.textFiltro}>
                                            Filtro
                                        </InputGroupText>
                                    </InputGroup>
                                </div>
                            </Col>
                        </Row>
                    </InputGroup>
                </div>
                <Table size="sm" striped className={styles.table}>
                    <thead>
                        <tr>
                            <th>Auxiliar</th>
                            <th>E-mail</th>
                            <th>CPF</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {auxiliaresFiltradas
                            .filter(r => r.usuario_ativo === (statusAuxiliar === "ativo"))
                            .map((auxiliar) => (
                                <tr key={auxiliar.auxiliar_id}>
                                    <td className={styles.tbody}>{auxiliar.auxiliar_nome}</td>
                                    <td className={styles.tbody}>{auxiliar.usuario_email}</td>
                                    <td className={styles.tbody}>{auxiliar.cpf}</td>
                                    <td>
                                        <Link to={`/visualizarAuxiliar/${auxiliar.auxiliar_id}`}>
                                            <Button size="sm" className={styles.buttonPerfil}>
                                                <AiOutlineEye size={17} className={styles.buttonIcon} />
                                                Ver Perfil
                                            </Button>
                                        </Link>
                                    </td>
                                </tr>
                            ))}
                            
                    </tbody>
                </Table>
            </div>
        </div>
    );
}

export default Auxiliares;
