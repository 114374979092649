import React, { useState, useEffect } from "react";
import styles from "./ModalVidasComponent.module.css";
import { Input, InputGroupText, InputGroup, Modal, ModalHeader, ModalBody, ModalFooter, Button, Table } from 'reactstrap';
import api from '../../service/api';
import { AiOutlineSearch, AiOutlineArrowRight } from 'react-icons/ai';
import ModalComponent from '../Modal/ModalComponent';

function ModalVidasComponent(props) {

    const [isOpen, setIsOpen] = useState(true);
    const [isSent, setIsSent] = useState(false);
    const [vidas, setVidas] = useState([]);
    const [vidasFiltrados, setVidasFiltratos] = useState([]);
    const [busca, setBusca] = useState("");

    useEffect(() => {
        api.get('vida').then(({ data }) => {
            const dataSort = data.sort(function (obj1, obj2) {
                return obj1.vida_nome < obj2.vida_nome ? -1 :
                    (obj1.vida_nome > obj2.vida_nome ? 1 : 0);
            });
            setVidas(dataSort);
        });
    }, []);

    useEffect(() => {
        const lowerBusca = busca?.toLocaleLowerCase() || ""; // Garantir que busca não seja undefined
        const filtrados = vidas.filter(
            (vida) =>
                vida?.vida_nome?.toLocaleLowerCase().includes(lowerBusca) // Garantir que vida_nome existe
        );
        setVidasFiltratos(filtrados);
    }, [busca, vidas]);

    const handleCancel = () => {
        setIsOpen(false);
        window.location.reload();
    }

    const enviarQuestionario = (vida_id, telefone) => {
        api.post('envioQuestionario', {
            idVida: vida_id,
            questionario: props.questionario
        }).then((response) => {
            let url = "https://api.whatsapp.com/send?phone=55" +
                parseInt(telefone.replace(/[^0-9]/g, '')) +
                "&text=Link para responder o questionário " + process.env.REACT_APP_FRONT_URL + "perguntas/" +
                response.data.linkCript;
            window.open(url);
            setIsSent(true);
        });
    }

    return (
        <div>
            {isSent ? <ModalComponent mensagem="Questionário enviado com sucesso!" icon="SucessoEnvioQuestionario" link="home" /> : null}
            <Modal
                centered
                size=""
                isOpen={isOpen}
            >
                <ModalHeader>
                    Escolha uma Vida
                </ModalHeader>
                <ModalBody>
                    <InputGroup>
                        <Input size="sm" placeholder="Busque pelo nome" className={styles.inputBusca} type="text" value={busca} onChange={(ev) => setBusca(ev.target.value)} />
                        <InputGroupText className={styles.textBuscar}>
                            <AiOutlineSearch size={17} className={styles.iconBuscar} />
                            Buscar
                        </InputGroupText>
                    </InputGroup>
                    <Table size="sm" striped className={styles.table}>
                        <thead>
                            <tr>
                                <th>Vida</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {busca ?
                                vidasFiltrados.map((vida) => (
                                    <tr key={vida.vida_id}>
                                        <td className={styles.tbody}>
                                            {vida.vida_nome || "Não informado"}
                                        </td>
                                        <td>
                                            <Button size="sm" onClick={() => enviarQuestionario(vida.vida_id, vida.telefone)} className={styles.buttonFormulario}>
                                                <AiOutlineArrowRight size={17} className={styles.buttonIcon} />
                                                Enviar
                                            </Button>
                                        </td>
                                    </tr>
                                ))
                                :
                                vidas.map((vida) => (
                                    <tr key={vida.vida_id}>
                                        <td className={styles.tbody}>
                                            {vida.vida_nome || "Não informado"}
                                        </td>
                                        <td>
                                            <Button size="sm" onClick={() => enviarQuestionario(vida.vida_id, vida.telefone)} className={styles.buttonFormulario}>
                                                <AiOutlineArrowRight size={17} className={styles.buttonIcon} />
                                                Enviar
                                            </Button>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" onClick={() => handleCancel()} className={styles.button}>
                        Cancelar
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default ModalVidasComponent;
