import { React, useState, useEffect, useMemo } from 'react';
import InputMask from 'react-input-mask';
import api from '../../service/api';
import styles from './index.module.css';
import { Link } from 'react-router-dom';
import NavbarComponent from '../../components/Navbar/NavbarComponent';
import NavMenuComponent from '../../components/NavMenu/NavMenuComponent';
import ModalComponent from '../../components/Modal/ModalComponent';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useFormik } from 'formik';

function CadastroParceiro() {
    const [isRegister, setIsRegister] = useState(false);
    const [buscaEspecialidade, setBuscaEspecialidade] = useState("");
    const [buscaEstado, setBuscaEstado] = useState("");
    const [especialidadesFiltradas, setEspecialidadesFiltradas] = useState([]);
    const [estadosFiltrados, setEstadosFiltrados] = useState([]);
    const [errorRegister, setErrorRegister] = useState("");
    const generos = [
        "Cisgênero",
        "Transgênero",
        "Não-binário",
        "Outro",
        "Não se aplica"
    ];

    const estados = useMemo(() => [
        "AC", "AL", "AP", "AM", "BA", "CE", "DF", "ES", "GO", "MA",
        "MT", "MS", "MG", "PA", "PB", "PR", "PE", "PI", "RJ", "RN",
        "RS", "RO", "RR", "SC", "SP", "SE", "TO"
    ], []);

    const especialidades = useMemo(() => [
        "Acupuntura", "Alergia e Imunologia", "Anestesiologia", "Angiologia",
        "Cancerologia", "Cardiologia", "Cirurgia Cardiovascular", "Cirurgia da Mão",
        "Cirurgia de Cabeça e Pescoço", "Cirurgia do Aparelho Digestivo",
        "Cirurgia Geral", "Cirurgia Pediátrica", "Cirurgia Plástica", "Cirurgia Torácica",
        "Cirurgia Vascular", "Clínica Médica", "Coloproctologia", "Dermatologia",
        "Endocrinologia e Metabologia", "Endoscopia", "Gastroenterologia",
        "Genética Médica", "Geriatria", "Ginecologia e Obstetrícia", "Hematologia e Hemoterapia",
        "Homeopatia", "Infectologia", "Mastologia", "Medicina de Família e Comunidade",
        "Medicina do Trabalho", "Medicina de Tráfego", "Medicina Esportiva",
        "Medicina Física e Reabilitação", "Medicina Intensiva", "Medicina Legal e Perícia Médica",
        "Medicina Nuclear", "Medicina Preventiva e Social", "Nefrologia",
        "Neurocirurgia", "Neurologia", "Nutrologia", "Oftalmologia",
        "Ortopedia e Traumatologia", "Otorrinolaringologia", "Patologia",
        "Patologia Clínica/Medicina Laboratorial", "Pediatria", "Pneumologia",
        "Psiquiatria", "Radiologia e Diagnóstico por Imagem", "Radioterapia",
        "Reumatologia", "Urologia"
    ], []);

    const validaCPF = (cpf) => {
        cpf = cpf.replace(/\./g, '').replace('-', '');
        let soma;
        let resto;

        if (cpf.length !== 11 ||
            cpf === "00000000000" ||
            cpf === "11111111111" ||
            cpf === "22222222222" ||
            cpf === "33333333333" ||
            cpf === "44444444444" ||
            cpf === "55555555555" ||
            cpf === "66666666666" ||
            cpf === "77777777777" ||
            cpf === "88888888888" ||
            cpf === "99999999999") {
            return false;
        }

        // Validação do primeiro dígito do CPF
        soma = 0;
        for (let i = 1; i <= 9; i++) {
            soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
        }
        resto = (soma * 10) % 11;

        if (resto === 10 || resto === 11) {
            resto = 0;
        }

        if (resto !== parseInt(cpf.substring(9, 10))) {
            return false;
        }

        // Validação do segundo dígito do CPF
        soma = 0;
        for (let i = 1; i <= 10; i++) {
            soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
        }
        resto = (soma * 10) % 11;

        if (resto === 10 || resto === 11) {
            resto = 0;
        }

        if (resto !== parseInt(cpf.substring(10, 11))) {
            return false;
        }

        return true;
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            nome: '',
            nome_social: '',
            data_nascimento: '',
            genero: '',
            telefone: '',
            cpf: '',
            email: '',
            senha: '',
            confirmar_senha: '',
            especialidade: '',
            conselho_regional: '',
            uf_conselho_regional: '',
            registro_parceiro: '',
            tipoParceiroId: '',
            nomeFantasia: '',
            razaoSocial: '',
            cnpj: '',
            cep: '',
            rua: '',
            numero: '',
            bairro: '',
            cidade: '',
            estado: '',
            pais: ''
        },
        validateOnBlur: true,
        validate: values => {
            const errors = {};

            // Validações para profissional de saúde
            if (values.tipoParceiroId === '1') {
                if (!values.nome) {
                    errors.nome = "O campo é obrigatório.";
                } else if (values.nome.length < 3) {
                    errors.nome = "O nome deve conter mais de 3 letras.";
                }

                const data_teste = /^(((0[1-9]|[12][0-9]|3[01])\/(0[13578]|10|12)\/(\d{4}))|(([0][1-9]|[12][0-9]|30)\/(0[469]|11)\/(\d{4}))|((0[1-9]|1[0-9]|2[0-8])\/(02)\/(\d{4}))|((29)(\.|-|\/)(02)\/([02468][048]00))|((29)\/(02)\/([13579][26]00))|((29)\/(02)\/([0-9][0-9][0][48]))|((29)\/(02)\/([0-9][0-9][2468][048]))|((29)\/(02)\/([0-9][0-9][13579][26])))$/;
                if (!values.data_nascimento) {
                    errors.data_nascimento = "O campo é obrigatório.";
                } else if (!data_teste.test(values.data_nascimento)) {
                    errors.data_nascimento = "Digite uma data válida dd/mm/aaaa.";
                }

                if (!values.genero) {
                    errors.genero = "O campo é obrigatório.";
                }

                // const cpf_teste = /^\d{3}\.\d{3}\.\d{333}-\d{2}$/;
                if (!values.cpf) {
                    errors.cpf = "O campo é obrigatório.";
                    // } else if (!cpf_teste.test(values.cpf)) {
                    //     errors.cpf = "Digite um CPF válido 000.000.000-00.";
                } else if (!validaCPF(values.cpf)) {
                    errors.cpf = "Digite um CPF válido 000.000.000-00.";
                }

                const telefone_teste = /^\((?:[14689][1-9]|2[12478]|3[1234578]|5[1345]|7[134579])\) (?:[2-8]|9[1-9])[0-9]{3}-[0-9]{4}$/;
                if (!values.telefone) {
                    errors.telefone = "O campo é obrigatório.";
                } else if (!telefone_teste.test(values.telefone)) {
                    errors.telefone = "Digite um telefone válido (99) 99999-9999.";
                }

                const email_teste = /^([a-zA-Z0-9_\-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})$/;
                if (!values.email) {
                    errors.email = "O campo é obrigatório.";
                } else if (!email_teste.test(values.email)) {
                    errors.email = "Digite um e-mail válido nome@email.com.";
                }

                if (!values.especialidade) {
                    errors.especialidade = "O campo é obrigatório.";
                }

                if (!values.conselho_regional) {
                    errors.conselho_regional = "O campo é obrigatório.";
                } else if (values.conselho_regional.length < 3) {
                    errors.conselho_regional = "O conselho regional deve conter pelo menos 3 letras.";
                }

                if (!values.uf_conselho_regional) {
                    errors.uf_conselho_regional = "O campo é obrigatório.";
                }

                if (!values.registro_parceiro) {
                    errors.registro_parceiro = "O campo é obrigatório.";
                } else if (values.registro_parceiro.length < 3) {
                    errors.registro_parceiro = "O registro parceiro deve conter pelo menos 3 números.";
                }

                // // Validar requisitos mínimos
                // const senha_teste = /^(?=.*\d)(?=.*[a-z]|[A-Z])[0-9a-zA-Z$*&@#]{8,}$/;

                if (!values.senha) {
                    errors.senha = "O campo é obrigatório.";
                    // } else if (!senha_teste.test(values.senha)) {
                    //     errors.senha = "A senha deve conter pelo menos 8 caracteres incluindo letras e números.";
                }

                if (values.confirmar_senha !== values.senha) {
                    errors.confirmar_senha = "As senhas não são iguais.";
                }
            }

            // Validações para empresa
            if (values.tipoParceiroId === '2') {
                if (!values.nomeFantasia) {
                    errors.nomeFantasia = "O campo Nome Fantasia é obrigatório.";
                }
                if (!values.razaoSocial) {
                    errors.razaoSocial = "O campo Razão Social é obrigatório.";
                }

                const cnpj_teste = /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/;
                if (!values.cnpj) {
                    errors.cnpj = "O campo CNPJ é obrigatório.";
                } else if (!cnpj_teste.test(values.cnpj)) {
                    errors.cnpj = "Digite um CNPJ válido 00.000.000/0001-00.";
                }

                if (!values.telefone) {
                    errors.telefone = "O campo é obrigatório.";
                }

                const email_teste = /^([a-zA-Z0-9_\-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})$/;
                if (!values.email) {
                    errors.email = "O campo é obrigatório.";
                } else if (!email_teste.test(values.email)) {
                    errors.email = "Digite um e-mail válido nome@email.com.";
                }

                if (!values.cep) {
                    errors.cep = "O campo CEP é obrigatório.";
                }
                if (!values.rua) {
                    errors.rua = "O campo Rua é obrigatório.";
                }
                if (!values.numero) {
                    errors.numero = "O campo Número é obrigatório.";
                }
                if (!values.bairro) {
                    errors.bairro = "O campo Bairro é obrigatório.";
                }
                if (!values.cidade) {
                    errors.cidade = "O campo Cidade é obrigatório.";
                }
                if (!values.estado) {
                    errors.estado = "O campo Estado é obrigatório.";
                }
                if (!values.pais) {
                    errors.pais = "O campo País é obrigatório.";
                }
            }

            if (!values.tipoParceiroId) {
                errors.tipoParceiroId = "O campo Tipo de Parceiro é obrigatório.";
            }

            return errors;
        },
        onSubmit: (values) => {
            setErrorRegister("");
            api.post('parceiro', {
                nome: values.nome,
                nomeSocial: values.nome_social,
                dataNascimento: values.data_nascimento,
                genero: values.genero,
                telefone: values.telefone,
                cpf: values.cpf,
                email: values.email,
                senha: values.senha,
                status: 'ativo',
                papel: 2,
                tipoParceiroId: values.tipoParceiroId,
                especialidade: values.especialidade,
                conselhoRegional: values.conselho_regional,
                ufConselhoRegional: values.uf_conselho_regional,
                registroParceiro: values.registro_parceiro,
                nomeFantasia: values.nomeFantasia,
                razaoSocial: values.razaoSocial,
                cnpj: values.cnpj,
                cep: values.cep,
                rua: values.rua,
                numero: values.numero,
                bairro: values.bairro,
                cidade: values.cidade,
                estado: values.estado,
                pais: values.pais
            }).then((response) => {
                setIsRegister(true);
            }).catch((error) => {
                if (error.response) {
                    setErrorRegister(error.response.status);
                }
            });
        }
    });

    useEffect(() => {
        const lowerBuscaEspecialidade = buscaEspecialidade.toLocaleLowerCase();
        const lowerBuscaEstado = buscaEstado.toLocaleLowerCase();
        setEspecialidadesFiltradas(especialidades.filter((esp) => esp.toLocaleLowerCase().includes(lowerBuscaEspecialidade)));
        setEstadosFiltrados(estados.filter((est) => est.toLocaleLowerCase().includes(lowerBuscaEstado)));
    }, [buscaEspecialidade, buscaEstado, especialidades, estados]);

    return (
        <div className={styles.mainDiv}>
            <NavbarComponent />
            <NavMenuComponent />
            {errorRegister ? <ModalComponent mensagem="E-mail, CPF ou Registro Parceiro já cadastrado" icon="Erro" /> : null}
            {isRegister ? <ModalComponent mensagem="Parceiro cadastrado(a) com sucesso" icon="Sucesso" link="parceiros" /> : null}

            <div className={styles.route}>
                Home &gt; Cadastrar Parceiro da Saúde
            </div>
            <div className={styles.title}>
                Cadastrar Parceiro da Saúde
            </div>
            <Container className={styles.container}>
                <div className={styles.divDadosGerais}>
                    Dados Gerais
                </div>
                <div className={styles.linha} />
                <Form inline onSubmit={formik.handleSubmit}>
                    <Row xs="1" sm="2" md="3">
                        <Col>
                            <FormGroup className="mb-2">
                                <Label for="tipoParceiroId" className={styles.label}>
                                    Tipo de Parceiro
                                </Label>
                                <UncontrolledDropdown className={formik.errors.tipoParceiroId ? styles.errorInput : styles.dropdown}>
                                    <DropdownToggle caret nav className={styles.dropdownToggle}>
                                        {formik.values.tipoParceiroId === '1' ? 'Profissional de Saúde' : 'Empresa'}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem onClick={() => formik.setFieldValue("tipoParceiroId", "1")}>
                                            Profissional de Saúde
                                        </DropdownItem>
                                        <DropdownItem onClick={() => formik.setFieldValue("tipoParceiroId", "2")}>
                                            Empresa
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                {formik.errors.tipoParceiroId ? <div className={styles.errorDiv}>{formik.errors.tipoParceiroId}</div> : null}
                            </FormGroup>
                        </Col>
                    </Row>

                    {/* Campos específicos para Profissional de Saúde */}
                    {formik.values.tipoParceiroId === '1' && (
                        <>
                            <Row xs="1" sm="2" md="3">
                                <Col>
                                    <FormGroup className="mb-2">
                                        <Label for="nome" className={styles.label}>Nome</Label>
                                        <Input
                                            autoFocus
                                            className={formik.errors.nome ? styles.errorInput : null}
                                            id="nome"
                                            bsSize="sm"
                                            name="nome"
                                            type="text"
                                            onChange={formik.handleChange}
                                            value={formik.values.nome}
                                        />
                                        {formik.errors.nome ? <div className={styles.errorDiv}>{formik.errors.nome}</div> : null}
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup className="mb-2">
                                        <Label for="nome_social" className={styles.label}>Nome Social</Label>
                                        <Input
                                            id="nome_social"
                                            bsSize="sm"
                                            name="nome_social"
                                            type="text"
                                            onChange={formik.handleChange}
                                            value={formik.values.nome_social}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup className="mb-2">
                                        <Label for="data_nascimento" className={styles.label}>Data de Nascimento</Label>
                                        <InputMask
                                            mask="99/99/9999"
                                            id="data_nascimento"
                                            name="data_nascimento"
                                            className={`${formik.errors.data_nascimento ? styles.errorInput : ''}`}
                                            onChange={formik.handleChange}
                                            value={formik.values.data_nascimento}
                                        >
                                            {(inputProps) => <Input {...inputProps} type="text" />}
                                        </InputMask>
                                        {formik.errors.data_nascimento ? <div className={styles.errorDiv}>{formik.errors.data_nascimento}</div> : null}
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup className="mb-2">
                                        <Label for="genero" className={styles.label}>Gênero</Label>
                                        <UncontrolledDropdown className={formik.errors.genero ? styles.errorInput : styles.dropdown}>
                                            <DropdownToggle caret nav className={styles.dropdownToggle}>
                                                {formik.values.genero}
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {generos.map((g, index) => (
                                                    <DropdownItem
                                                        className={styles.dropdownItem}
                                                        id="genero"
                                                        bsSize="sm"
                                                        name="genero"
                                                        value={generos[index]}
                                                        onClick={formik.handleChange}>
                                                        {g}
                                                    </DropdownItem>
                                                ))}
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                        {formik.errors.genero ? <div className={styles.errorDiv}>{formik.errors.genero}</div> : null}
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup className="mb-2">
                                        <Label for="cpf" className={styles.label}>CPF</Label>
                                        <InputMask
                                            mask="999.999.999-99"
                                            id="cpf"
                                            name="cpf"
                                            className={`${formik.errors.cpf ? styles.errorInput : ''}`}
                                            onChange={formik.handleChange}
                                            value={formik.values.cpf}
                                        >
                                            {(inputProps) => <Input {...inputProps} type="text" />}
                                        </InputMask>
                                        {formik.errors.cpf ? <div className={styles.errorDiv}>{formik.errors.cpf}</div> : null}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <div className={styles.divContato}>Contato</div>
                            <div className={styles.linha} />
                            <Row xs="1" sm="2" md="3">
                                <Col>
                                    <FormGroup className="mb-2">
                                        <Label for="telefone" className={styles.label}>Telefone</Label>
                                        <InputMask
                                            mask="(99) 99999-9999"
                                            id="telefone"
                                            name="telefone"
                                            className={`${formik.errors.telefone ? styles.errorInput : ''}`}
                                            onChange={formik.handleChange}
                                            value={formik.values.telefone}
                                        >
                                            {(inputProps) => <Input {...inputProps} type="text" />}
                                        </InputMask>
                                        {formik.errors.telefone ? <div className={styles.errorDiv}>{formik.errors.telefone}</div> : null}
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup className="mb-2">
                                        <Label for="email" className={styles.label}>E-mail</Label>
                                        <Input
                                            className={formik.errors.email ? styles.errorInput : null}
                                            id="email"
                                            bsSize="sm"
                                            name="email"
                                            type="email"
                                            onChange={formik.handleChange}
                                            value={formik.values.email}
                                        />
                                        {formik.errors.email ? <div className={styles.errorDiv}>{formik.errors.email}</div> : null}
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup className="mb-2">
                                        <Label for="senha" className={styles.label}>Senha</Label>
                                        <Input
                                            className={formik.errors.senha ? styles.errorInput : null}
                                            id="senha"
                                            bsSize="sm"
                                            name="senha"
                                            type="password"
                                            minlength="8"
                                            onChange={formik.handleChange}
                                            value={formik.values.senha}
                                        />
                                        {formik.errors.senha ? <div className={styles.errorDiv}>{formik.errors.senha}</div> : null}
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup className="mb-2">
                                        <Label for="confirmar_senha" className={styles.label}>Confirmar senha</Label>
                                        <Input
                                            className={formik.errors.confirmar_senha ? styles.errorInput : null}
                                            id="confirmar_senha"
                                            bsSize="sm"
                                            name="confirmar_senha"
                                            type="password"
                                            onChange={formik.handleChange}
                                            value={formik.values.confirmar_senha}
                                        />
                                        {formik.errors.confirmar_senha ? <div className={styles.errorDiv}>{formik.errors.confirmar_senha}</div> : null}
                                    </FormGroup>
                                </Col>
                            </Row>

                            <div className={styles.divDadosParceiros}>Dados Parceiros</div>
                            <div className={styles.linha} />
                            <Row xs="1" sm="2" md="3">
                                <Col>
                                    <FormGroup className="mb-2">
                                        <Label for="especialidade" className={styles.label}>Especialidade</Label>
                                        <UncontrolledDropdown className={formik.errors.especialidade ? styles.errorInput : styles.dropdown}>
                                            <DropdownToggle caret nav className={styles.dropdownToggle}>
                                                {formik.values.especialidade}
                                            </DropdownToggle>
                                            <DropdownMenu className={styles.dropdownMenu}>
                                                <Input size="sm" placeholder="Buscar" className={styles.inputBusca} type="text" value={buscaEspecialidade} onChange={(ev) => setBuscaEspecialidade(ev.target.value)} />
                                                <DropdownItem divider />
                                                {buscaEspecialidade ?
                                                    especialidadesFiltradas.map((e, index) => (
                                                        <DropdownItem
                                                            className={styles.dropdownItem}
                                                            id="especialidade"
                                                            bsSize="sm"
                                                            name="especialidade"
                                                            value={especialidadesFiltradas[index]}
                                                            onClick={formik.handleChange}>
                                                            {e}
                                                        </DropdownItem>
                                                    ))
                                                    :
                                                    especialidades.map((e, index) => (
                                                        <DropdownItem
                                                            className={styles.dropdownItem}
                                                            id="especialidade"
                                                            bsSize="sm"
                                                            name="especialidade"
                                                            value={especialidades[index]}
                                                            onClick={formik.handleChange}>
                                                            {e}
                                                        </DropdownItem>
                                                    ))}
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                        {formik.errors.especialidade ? <div className={styles.errorDiv}>{formik.errors.especialidade}</div> : null}
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup className="mb-2">
                                        <Label for="conselho_regional" className={styles.label}>Conselho Regional</Label>
                                        <Input
                                            className={formik.errors.conselho_regional ? styles.errorInput : null}
                                            id="conselho_regional"
                                            bsSize="sm"
                                            name="conselho_regional"
                                            type="text"
                                            onChange={formik.handleChange}
                                            value={formik.values.conselho_regional}
                                        />
                                        {formik.errors.conselho_regional ? <div className={styles.errorDiv}>{formik.errors.conselho_regional}</div> : null}
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup className="mb-2">
                                        <Label for="uf_conselho_regional" className={styles.label}>UF Conselho Regional</Label>
                                        <UncontrolledDropdown className={formik.errors.uf_conselho_regional ? styles.errorInput : styles.dropdown}>
                                            <DropdownToggle caret nav className={styles.dropdownToggle}>
                                                {formik.values.uf_conselho_regional}
                                            </DropdownToggle>
                                            <DropdownMenu className={styles.dropdownMenu}>
                                                <Input size="sm" placeholder="Buscar" className={styles.inputBusca} type="text" value={buscaEstado} onChange={(ev) => setBuscaEstado(ev.target.value)} />
                                                <DropdownItem divider />
                                                {buscaEstado ?
                                                    estadosFiltrados.map((estado, index) => (
                                                        <DropdownItem
                                                            className={styles.dropdownItem}
                                                            id="uf_conselho_regional"
                                                            bsSize="sm"
                                                            name="uf_conselho_regional"
                                                            value={estadosFiltrados[index]}
                                                            onClick={formik.handleChange}>
                                                            {estado}
                                                        </DropdownItem>
                                                    ))
                                                    :
                                                    estados.map((estado, index) => (
                                                        <DropdownItem
                                                            className={styles.dropdownItem}
                                                            id="uf_conselho_regional"
                                                            bsSize="sm"
                                                            name="uf_conselho_regional"
                                                            value={estados[index]}
                                                            onClick={formik.handleChange}>
                                                            {estado}
                                                        </DropdownItem>
                                                    ))}
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                        {formik.errors.uf_conselho_regional ? <div className={styles.errorDiv}>{formik.errors.uf_conselho_regional}</div> : null}
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup className="mb-2">
                                        <Label for="registro_parceiro" className={styles.label}>Registro Parceiro</Label>
                                        <Input
                                            className={formik.errors.registro_parceiro ? styles.errorInput : null}
                                            id="registro_parceiro"
                                            bsSize="sm"
                                            name="registro_parceiro"
                                            type="text"
                                            onChange={formik.handleChange}
                                            value={formik.values.registro_parceiro}
                                        />
                                        {formik.errors.registro_parceiro ? <div className={styles.errorDiv}>{formik.errors.registro_parceiro}</div> : null}
                                    </FormGroup>
                                </Col>
                            </Row>
                        </>
                    )}

                    {/* Campos específicos para Empresa */}
                    {formik.values.tipoParceiroId === '2' && (
                        <>
                            <div className={styles.divDadosParceiros}>Dados da Empresa</div>
                            <div className={styles.linha} />
                            <Row xs="1" sm="2" md="3">
                                <Col>
                                    <FormGroup className="mb-2">
                                        <Label for="nome" className={styles.label}>Nome</Label>
                                        <Input
                                            autoFocus
                                            className={formik.errors.nome ? styles.errorInput : null}
                                            id="nome"
                                            bsSize="sm"
                                            name="nome"
                                            type="text"
                                            onChange={formik.handleChange}
                                            value={formik.values.nome}
                                        />
                                        {formik.errors.nome ? <div className={styles.errorDiv}>{formik.errors.nome}</div> : null}
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup className="mb-2">
                                        <Label for="nome_social" className={styles.label}>Nome Social</Label>
                                        <Input
                                            id="nome_social"
                                            bsSize="sm"
                                            name="nome_social"
                                            type="text"
                                            onChange={formik.handleChange}
                                            value={formik.values.nome_social}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup className="mb-2">
                                        <Label for="nomeFantasia" className={styles.label}>Nome Fantasia</Label>
                                        <Input
                                            className={formik.errors.nomeFantasia ? styles.errorInput : null}
                                            id="nomeFantasia"
                                            bsSize="sm"
                                            name="nomeFantasia"
                                            type="text"
                                            onChange={formik.handleChange}
                                            value={formik.values.nomeFantasia}
                                        />
                                        {formik.errors.nomeFantasia ? <div className={styles.errorDiv}>{formik.errors.nomeFantasia}</div> : null}
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup className="mb-2">
                                        <Label for="razaoSocial" className={styles.label}>Razão Social</Label>
                                        <Input
                                            className={formik.errors.razaoSocial ? styles.errorInput : null}
                                            id="razaoSocial"
                                            bsSize="sm"
                                            name="razaoSocial"
                                            type="text"
                                            onChange={formik.handleChange}
                                            value={formik.values.razaoSocial}
                                        />
                                        {formik.errors.razaoSocial ? <div className={styles.errorDiv}>{formik.errors.razaoSocial}</div> : null}
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup className="mb-2">
                                        <Label for="cnpj" className={styles.label}>CNPJ</Label>
                                        <InputMask
                                            mask="99.999.999/9999-99"
                                            id="cnpj"
                                            name="cnpj"
                                            className={`${formik.errors.cnpj ? styles.errorInput : ''}`}
                                            onChange={formik.handleChange}
                                            value={formik.values.cnpj}
                                        >
                                            {(inputProps) => <Input {...inputProps} type="text" />}
                                        </InputMask>
                                        {formik.errors.cnpj ? <div className={styles.errorDiv}>{formik.errors.cnpj}</div> : null}
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup className="mb-2">
                                        <Label for="senha" className={styles.label}>Senha</Label>
                                        <Input
                                            className={formik.errors.senha ? styles.errorInput : null}
                                            id="senha"
                                            bsSize="sm"
                                            name="senha"
                                            type="password"
                                            minlength="8"
                                            onChange={formik.handleChange}
                                            value={formik.values.senha}
                                        />
                                        {formik.errors.senha ? <div className={styles.errorDiv}>{formik.errors.senha}</div> : null}
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup className="mb-2">
                                        <Label for="confirmar_senha" className={styles.label}>Confirmar senha</Label>
                                        <Input
                                            className={formik.errors.confirmar_senha ? styles.errorInput : null}
                                            id="confirmar_senha"
                                            bsSize="sm"
                                            name="confirmar_senha"
                                            type="password"
                                            onChange={formik.handleChange}
                                            value={formik.values.confirmar_senha}
                                        />
                                        {formik.errors.confirmar_senha ? <div className={styles.errorDiv}>{formik.errors.confirmar_senha}</div> : null}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <div className={styles.divContato}>Contato</div>
                            <div className={styles.linha} />
                            <Row xs="1" sm="2" md="3">
                                <Col>
                                    <FormGroup className="mb-2">
                                        <Label for="telefone" className={styles.label}>Telefone</Label>
                                        <InputMask
                                            mask="(99) 99999-9999"
                                            id="telefone"
                                            name="telefone"
                                            className={`${formik.errors.telefone ? styles.errorInput : ''}`}
                                            onChange={formik.handleChange}
                                            value={formik.values.telefone}
                                        >
                                            {(inputProps) => <Input {...inputProps} type="text" />}
                                        </InputMask>
                                        {formik.errors.telefone ? <div className={styles.errorDiv}>{formik.errors.telefone}</div> : null}
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup className="mb-2">
                                        <Label for="email" className={styles.label}>E-mail</Label>
                                        <Input
                                            className={formik.errors.email ? styles.errorInput : null}
                                            id="email"
                                            bsSize="sm"
                                            name="email"
                                            type="email"
                                            onChange={formik.handleChange}
                                            value={formik.values.email}
                                        />
                                        {formik.errors.email ? <div className={styles.errorDiv}>{formik.errors.email}</div> : null}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <div className={styles.divContato}>Endereço</div>
                            <div className={styles.linha} />
                            <Row xs="1" sm="2" md="3">
                                <Col>
                                    <FormGroup className="mb-2">
                                        <Label for="cep" className={styles.label}>CEP</Label>
                                        <Input
                                            className={formik.errors.cep ? styles.errorInput : null}
                                            id="cep"
                                            bsSize="sm"
                                            name="cep"
                                            type="text"
                                            onChange={formik.handleChange}
                                            value={formik.values.cep}
                                        />
                                        {formik.errors.cep ? <div className={styles.errorDiv}>{formik.errors.cep}</div> : null}
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup className="mb-2">
                                        <Label for="rua" className={styles.label}>Rua</Label>
                                        <Input
                                            className={formik.errors.rua ? styles.errorInput : null}
                                            id="rua"
                                            bsSize="sm"
                                            name="rua"
                                            type="text"
                                            onChange={formik.handleChange}
                                            value={formik.values.rua}
                                        />
                                        {formik.errors.rua ? <div className={styles.errorDiv}>{formik.errors.rua}</div> : null}
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup className="mb-2">
                                        <Label for="numero" className={styles.label}>Número</Label>
                                        <Input
                                            className={formik.errors.numero ? styles.errorInput : null}
                                            id="numero"
                                            bsSize="sm"
                                            name="numero"
                                            type="text"
                                            onChange={formik.handleChange}
                                            value={formik.values.numero}
                                        />
                                        {formik.errors.numero ? <div className={styles.errorDiv}>{formik.errors.numero}</div> : null}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row xs="1" sm="2" md="3">
                                <Col>
                                    <FormGroup className="mb-2">
                                        <Label for="bairro" className={styles.label}>Bairro</Label>
                                        <Input
                                            className={formik.errors.bairro ? styles.errorInput : null}
                                            id="bairro"
                                            bsSize="sm"
                                            name="bairro"
                                            type="text"
                                            onChange={formik.handleChange}
                                            value={formik.values.bairro}
                                        />
                                        {formik.errors.bairro ? <div className={styles.errorDiv}>{formik.errors.bairro}</div> : null}
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup className="mb-2">
                                        <Label for="cidade" className={styles.label}>Cidade</Label>
                                        <Input
                                            className={formik.errors.cidade ? styles.errorInput : null}
                                            id="cidade"
                                            bsSize="sm"
                                            name="cidade"
                                            type="text"
                                            onChange={formik.handleChange}
                                            value={formik.values.cidade}
                                        />
                                        {formik.errors.cidade ? <div className={styles.errorDiv}>{formik.errors.cidade}</div> : null}
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup className="mb-2">
                                        <Label for="estado" className={styles.label}>Estado</Label>
                                        <Input
                                            className={formik.errors.estado ? styles.errorInput : null}
                                            id="estado"
                                            bsSize="sm"
                                            name="estado"
                                            type="text"
                                            onChange={formik.handleChange}
                                            value={formik.values.estado}
                                        />
                                        {formik.errors.estado ? <div className={styles.errorDiv}>{formik.errors.estado}</div> : null}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row xs="1" sm="2" md="3">
                                <Col>
                                    <FormGroup className="mb-2">
                                        <Label for="pais" className={styles.label}>País</Label>
                                        <Input
                                            className={formik.errors.pais ? styles.errorInput : null}
                                            id="pais"
                                            bsSize="sm"
                                            name="pais"
                                            type="text"
                                            onChange={formik.handleChange}
                                            value={formik.values.pais}
                                        />
                                        {formik.errors.pais ? <div className={styles.errorDiv}>{formik.errors.pais}</div> : null}
                                    </FormGroup>
                                </Col>
                            </Row>
                        </>
                    )}

                    <div className={styles.divButton}>
                        <Link to='/parceiros'><Button size="sm" className={styles.buttonCacelar}>Cancelar</Button></Link>
                        <Button size="sm" type="submit" className={styles.buttonCadastrar}>Cadastrar</Button>
                    </div>
                </Form>
            </Container>
        </div>
    );
}

export default CadastroParceiro;
