import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import api from '../../service/api';
import styles from './index.module.css';
import NavbarComponent from '../../components/Navbar/NavbarComponent';
import NavMenuComponent from '../../components/NavMenu/NavMenuComponent';
import { Row, Col, Button } from "reactstrap";
import { MdOutlineModeEditOutline } from "react-icons/md";

function VisualizarParceiro() {
    const { id } = useParams();

    const [parceiro, setParceiro] = useState([]);

    useEffect(() => {
        api.get('parceiro/' + id).then(({ data }) => {
            setParceiro(data);
        });
    }, [id]);

    // Verifica se o parceiro é uma empresa
    const isEmpresa = parceiro.tipo_parceiro === "empresa";

    return (
        <div className={styles.mainDiv}>
            <NavbarComponent />
            <NavMenuComponent />

            <div className={styles.route}>
                Home &gt; Lista de Parceiros &gt; Perfil Parceiro
            </div>
            <div className={styles.title}>
                Perfil Parceiro
            </div>
            <div className={styles.divButton}>
                <Link to={`/editarParceiro/${parceiro.parceiro_id}`}>
                    <Button size="sm" className={styles.buttonEditar}>
                        <MdOutlineModeEditOutline size={17} className={styles.buttonIcon} />Editar
                    </Button>
                </Link>
            </div>
            <div responsive className={styles.container}>
                <div className={styles.divDadosGerais}>
                    Dados Gerais
                </div>
                <div className={styles.linha} />
                <div className={styles.divDadosParceiro}>
                    <Row xs="3">
                        <Col>
                            Nome <p className={styles.p}>{parceiro.parceiro_nome}</p>
                        </Col>
                        <Col>
                            Nome Social <p className={styles.p}>{parceiro.nome_social}</p>
                        </Col>
                        <Col>
                            Data de Nascimento <p className={styles.p}>{parceiro.data_nascimento}</p>
                        </Col>
                        <Col>
                            Gênero <p className={styles.p}>{parceiro.genero}</p>
                        </Col>
                        <Col>
                            CPF <p className={styles.p}>{parceiro.cpf}</p>
                        </Col>
                    </Row>
                </div>
                <div className={styles.divContato}>
                    Contato
                </div>
                <div className={styles.linha} />
                <div className={styles.divContatoParceiro}>
                    <Row>
                        <Col xs="6">
                            Telefone <p className={styles.p}>{parceiro.telefone}</p>
                        </Col>
                        <Col xs="6">
                            E-mail <p className={styles.p}>{parceiro.usuario_email}</p>
                        </Col>
                    </Row>
                </div>

                {/* Renderização condicional para campos específicos */}
                {isEmpresa ? (
                    <>
                        <div className={styles.divDadosParceiros}>
                            Dados da Empresa
                        </div>
                        <div className={styles.linha} />
                        <div className={styles.divInfoParceiro}>
                            <Row xs="3">
                                <Col>
                                    Nome Fantasia <p className={styles.p}>{parceiro.nome_fantasia}</p>
                                </Col>
                                <Col>
                                    Razão Social <p className={styles.p}>{parceiro.razao_social}</p>
                                </Col>
                                <Col>
                                    CNPJ <p className={styles.p}>{parceiro.cnpj}</p>
                                </Col>
                                <Col>
                                    Endereço <p className={styles.p}>{`${parceiro.empresa_rua}, ${parceiro.empresa_numero}`}</p>
                                </Col>
                                <Col>
                                    Bairro <p className={styles.p}>{parceiro.empresa_bairro}</p>
                                </Col>
                                <Col>
                                    Cidade/Estado <p className={styles.p}>{`${parceiro.empresa_cidade} - ${parceiro.empresa_estado}`}</p>
                                </Col>
                                <Col>
                                    CEP <p className={styles.p}>{parceiro.empresa_cep}</p>
                                </Col>
                                <Col>
                                    País <p className={styles.p}>{parceiro.empresa_pais}</p>
                                </Col>
                            </Row>
                        </div>
                    </>
                ) : (
                    <>
                        <div className={styles.divDadosParceiros}>
                            Dados Profissionais
                        </div>
                        <div className={styles.linha} />
                        <div className={styles.divInfoParceiro}>
                            <Row xs="3">
                                <Col>
                                    Especialidade <p className={styles.p}>{parceiro.especialidade}</p>
                                </Col>
                                <Col>
                                    Conselho Regional <p className={styles.p}>{parceiro.conselho_regional}</p>
                                </Col>
                                <Col>
                                    UF Conselho Regional <p className={styles.p}>{parceiro.uf_conselho_regional}</p>
                                </Col>
                                <Col>
                                    Registro Parceiro <p className={styles.p}>{parceiro.registro_profissional}</p>
                                </Col>
                            </Row>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default VisualizarParceiro;
