import React from "react";
import styles from "./RespostasComponent.module.css";
import { Button } from 'reactstrap';

function RespostasComponent(props) {

    const listaRespostas = {
        0: ["Atualizando respostas"],
        1: ["Não", "Às vezes", "Quase sempre", "Sempre"],
        2: ["Sim", "Não"],
        3: ["Nenhum", "Leve", "Moderada", "Grave", "Muito Grave"],
        4: ["Muito satisfeito", "Satisfeito", "Indiferente", "Insatisfeito", "Muito insatisfeito"],
        5: ["Não interfere", "Interfere um pouco", "Interfere de algum modo", "Interfere muito", "Interfere extremamente"],
        6: ["Não percebem", "Percebem um pouco", "Percebem de algum modo", "Percebem muito", "Percebem extremamente"],
        7: ["Não estou preocupado", "Um pouco preocupado", "De algum modo preocupado", "Muito preocupado", "Extremamente preocupado"]
    }

    return (
        <div>
            {listaRespostas[props.tipo].map((respostas, index) => (
                <Button
                    className={styles.buttonResposta}
                    size="sm"
                    onClick={(ev) => props.getResposta(ev.target.value)}
                    value={listaRespostas[props.tipo][index]}
                >
                    {respostas}
                </Button>
            ))}
        </div>
    );
};

export default RespostasComponent;