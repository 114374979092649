import React from 'react';
import ReactECharts from 'echarts-for-react';
import './DashBoardComponent.css';

const getRadarChartOptions = (vida) => ({
  tooltip: {},
  radar: {
    indicator: [
      { name: 'Ansiedade', max: 5 },
      { name: 'Depressão', max: 5 },
      { name: 'Bipolaridade', max: 5 },
      { name: 'Dependência', max: 5 },
      { name: 'Alimentação', max: 5 },
      { name: 'IRIS', max: 5 },
      { name: 'Sono', max: 5 },
    ],
  },
  series: [
    {
      name: 'Tendências',
      type: 'radar',
      data: [
        {
          value: vida.tendencias,
          name: vida.nome,
        },
      ],
    },
  ],
});

const getBarChartOptions = (vida) => ({
  tooltip: {},
  xAxis: {
    type: 'category',
    data: ['Ansiedade', 'Depressão', 'Bipolaridade', 'Dependência', 'Alimentação', 'IRIS', 'Sono'],
    axisLabel: {
      interval: 0, 
      rotate: 45, 
    },
  },
  yAxis: {
    type: 'value',
    max: 5, // O máximo do gráfico é 5
  },
  grid: {
    bottom: 80, 
  },
  series: [
    {
      name: 'Nível',
      type: 'bar',
      data: vida.tendencias,
    },
  ],
});

const DashBoardComponent = ({ vidas }) => {
  return (
    <div className="dashboardContainer">
      {vidas.map((vida, index) => (
        <div key={index} className="chartContainer">
          <div className="chartsRow">
            <div className="chartItem">
              <ReactECharts
                option={getBarChartOptions(vida)}
                style={{ height: 300, width: '100%' }}
              />
            </div>
            <div className="chartItem">
              <ReactECharts
                option={getRadarChartOptions(vida)}
                style={{ height: 300, width: '100%' }}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default DashBoardComponent;
