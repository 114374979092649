import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import NavbarComponent from '../../components/Navbar/NavbarComponent';
import NavMenuComponent from '../../components/NavMenu/NavMenuComponent';
import LoadingComponent from '../../components/Loading/LoadingComponent';
import DashBoardComponent from '../../components/DashBoard/DashBoardComponent';
import styles from './index.module.css';
import { Row, Col, Table, Input, InputGroup, InputGroupText, Button } from 'reactstrap';
import { AiOutlineSearch, AiOutlineEye } from 'react-icons/ai';
import api from '../../service/api';

function Home() {
    const [respostas, setRespostas] = useState([]);
    const [respostasFiltradas, setRespostasFiltradas] = useState([]);
    const [busca, setBusca] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [vidas, setVidas] = useState([]);

    const [idadeFiltro, setIdadeFiltro] = useState('');
    const [setorFiltro, setSetorFiltro] = useState('');
    const [tendenciaFiltro, setTendenciaFiltro] = useState('');

    // Fetch inicial de dados gerais
    const fetchGraficosGerais = async () => {
        try {
            const response = await api.get('/dashboard/tendencias-transtornos', {
                params: { startDate: '2000-01-01', endDate: '2026-12-31' },
            });
            const transtornos = [
                'Ansiedade',
                'Depressão',
                'Bipolaridade',
                'Dependência',
                'Alimentação',
                'IRIS',
                'SONO',
            ];
            const tendencias = transtornos.map((transtorno) => {
                const item = response.data.find((dado) => dado.transtorno === transtorno);
                return item ? parseInt(item.quantidade, 10) : 0;
            });
            setVidas([{ nome: 'Tendências Gerais', tendencias }]);
        } catch (error) {
            setError(error.response ? error.response.data.message : error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchRespostas = async () => {
        try {
            const response = await api.get('/vida/ultimas-respostas');
            setRespostas(response.data);
        } catch (error) {
            setError(error.response ? error.response.data.message : error.message);
        }
    };

    useEffect(() => {
        fetchRespostas();
        fetchGraficosGerais();
    }, []);

    useEffect(() => {
        const lowerBusca = busca.toLowerCase();
        const filtrados = respostas.filter((resposta) =>
            resposta.nome && resposta.nome.toLowerCase().includes(lowerBusca)
        );
        setRespostasFiltradas(filtrados);
    }, [busca, respostas]);

    if (isLoading) {
        return <LoadingComponent />;
    }

    if (error) {
        return <div>Erro: {error}</div>;
    }

    return (
        <div className={styles.mainDiv}>
            <NavbarComponent />
            <NavMenuComponent />

            <div className={styles.route}>
                Home &gt; Dashboard
            </div>
            <div className={styles.title}>
                Dashboard e Lista de Respostas
            </div>
            <Row className={styles.container}>
                <Col lg="7" md="12" className={styles.leftColumn}>
                    <Row className={styles.filterRow}>
                        <Col xs="12" md="4">
                            <InputGroup className={styles.inputGroup}>
                                <Input
                                    size="sm"
                                    placeholder="Idade"
                                    className={styles.inputBusca}
                                    type="text"
                                    value={idadeFiltro}
                                    onChange={(e) => setIdadeFiltro(e.target.value)}
                                />
                                <InputGroupText className={styles.textBuscar}>
                                    <AiOutlineSearch size={17} className={styles.iconBuscar} />
                                    Buscar
                                </InputGroupText>
                            </InputGroup>
                        </Col>
                        <Col xs="12" md="4">
                            <InputGroup className={styles.inputGroup}>
                                <Input
                                    size="sm"
                                    placeholder="Setor"
                                    className={styles.inputBusca}
                                    type="text"
                                    value={setorFiltro}
                                    onChange={(e) => setSetorFiltro(e.target.value)}
                                />
                                <InputGroupText className={styles.textBuscar}>
                                    <AiOutlineSearch size={17} className={styles.iconBuscar} />
                                    Buscar
                                </InputGroupText>
                            </InputGroup>
                        </Col>
                        <Col xs="12" md="4">
                            <InputGroup className={styles.inputGroup}>
                                <Input
                                    size="sm"
                                    placeholder="Tendência"
                                    className={styles.inputBusca}
                                    type="text"
                                    value={tendenciaFiltro}
                                    onChange={(e) => setTendenciaFiltro(e.target.value)}
                                />
                                <InputGroupText className={styles.textBuscar}>
                                    <AiOutlineSearch size={17} className={styles.iconBuscar} />
                                    Buscar
                                </InputGroupText>
                            </InputGroup>
                        </Col>
                    </Row>

                    <div className={styles.chartColumn}>
                        <DashBoardComponent vidas={vidas} />
                    </div>
                </Col>

                <Col lg="5" md="12" className={styles.rightColumn}>
                    <div className={styles.divInput}>
                        <InputGroup>
                            <Input
                                size="sm"
                                placeholder="Nome"
                                className={styles.inputBusca}
                                type="text"
                                value={busca}
                                onChange={(ev) => setBusca(ev.target.value)}
                            />
                            <InputGroupText className={styles.textBuscar}>
                                <AiOutlineSearch size={17} className={styles.iconBuscar} />
                                Buscar
                            </InputGroupText>
                        </InputGroup>
                    </div>

                    <Table size="sm" striped className={styles.table}>
                        <thead>
                            <tr>
                                <th>Nome</th>
                                <th>Parceiro</th>
                                <th>Questionário</th>
                                <th>Última Resposta</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {busca
                                ? respostasFiltradas.map((resposta) => (
                                      <tr key={resposta.id}>
                                          <td className={styles.tbody}>{resposta.nome}</td>
                                          <td className={styles.tbody}>{resposta.parceiro_nome}</td>
                                          <td className={styles.tbody}>{resposta.questionario_nome}</td>
                                          <td className={styles.tbody}>{resposta.ultima_resposta}</td>
                                          <td>
                                              <Link
                                                  to={`/vida/${resposta.id}`}
                                                  state={{ nome: resposta.nome }}
                                              >
                                                  <Button size="sm" className={styles.buttonPerfil}>
                                                      <AiOutlineEye
                                                          size={17}
                                                          className={styles.buttonIcon}
                                                      />
                                                      Ver Tendências
                                                  </Button>
                                              </Link>
                                          </td>
                                      </tr>
                                  ))
                                : respostas.map((resposta) => (
                                      <tr key={resposta.id}>
                                          <td className={styles.tbody}>{resposta.nome}</td>
                                          <td className={styles.tbody}>{resposta.parceiro_nome}</td>
                                          <td className={styles.tbody}>{resposta.questionario_nome}</td>
                                          <td className={styles.tbody}>{resposta.ultima_resposta}</td>
                                          <td>
                                              <Link
                                                  to={`/vida/${resposta.id}`}
                                                  state={{ nome: resposta.nome }}
                                              >
                                                  <Button size="sm" className={styles.buttonPerfil}>
                                                      <AiOutlineEye
                                                          size={17}
                                                          className={styles.buttonIcon}
                                                      />
                                                      Ver Tendências
                                                  </Button>
                                              </Link>
                                          </td>
                                      </tr>
                                  ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </div>
    );
}

export default Home;
