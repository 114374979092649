import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import styles from "./ModalComponent.module.css";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { FiUserCheck } from 'react-icons/fi';
import { BiError } from 'react-icons/bi';
import { BsClipboardCheck } from 'react-icons/bs';

function ModalComponent(props) {

    const [isOpen, setIsOpen] = useState(true);
    const [icon, setIcon] = useState("");

    useEffect(() => {
        switch (props.icon) {
            case 'Sucesso': setIcon(<FiUserCheck size="80" className={styles.icon} />);
                break;
            case 'Erro': setIcon(<BiError size="80" className={styles.icon} />);
                break;
            case 'SucessoEnvioQuestionario': setIcon(<BsClipboardCheck size="80" className={styles.icon} />);
                break;
            default:
                console.log("Default");
        }
    }, [props.icon]);


    const handleContinue = () => {
        if (props.recarregar) {
            setIsOpen(false);
            window.location.reload();
        } else {
            setIsOpen(false);
        }

    }

    return (
        <div>
            <Modal
                centered
                size=""
                isOpen={isOpen}
            >
                <ModalHeader />
                <ModalBody>
                    <div className={styles.icon} >
                        {icon}
                    </div>
                    <div className={styles.divText}>
                        {props.mensagem}
                    </div>
                </ModalBody>
                <ModalFooter>
                    {' '}
                    {props.link ?
                        <Link to={`/${props.link}`}>
                            <Button size="sm" onClick={() => setIsOpen(!isOpen)} className={styles.button}>
                                Continuar
                        </Button>
                        </Link>
                        :
                        <Button size="sm" onClick={() => handleContinue(props.recarregar)} className={styles.button}>
                            Continuar
                        </Button>
                    }
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default ModalComponent;