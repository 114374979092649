import React, { useState, useContext } from "react";
import { Context } from '../../context/authContext';
import styles from "./NavbarComponent.module.css";
import logoHb4RedBranca from '../../assets/images/hb4_reduzida_branca.png'
import perfil from '../../assets/images/perfil.jpg'
import { Navbar, NavbarBrand, NavbarToggler, Collapse, Nav, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

function NavbarComponent() {

    const email = localStorage.getItem("email");
    const [isOpen, setIsOpen] = useState(false);
    const { handleClickLogout } = useContext(Context);

    return (
        <div className={styles.mainDiv}>
            <Navbar expand="sm" className={styles.navbar} >
                <NavbarBrand href="/home">
                    <div className={styles.divImage}>
                        <img href="/home" alt="Home" src={logoHb4RedBranca} className={styles.image} />
                    </div>
                </NavbarBrand>
                <NavbarToggler onClick={() => setIsOpen(!isOpen)} />
                <Collapse isOpen={isOpen} navbar>
                    <Nav className="me-auto" navbar >

                    </Nav>
                    <div className={styles.divDropdownToggle}>
                        <Nav>
                            <div className={styles.divImagePerfil}>
                                <img src={perfil} alt="Perfil" className={styles.imagePerfil} />
                            </div>
                            <UncontrolledDropdown inNavbar nav >
                                <DropdownToggle caret nav className={styles.dropdownToggle}>
                                    {email}
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem className={styles.itensDropdown}>
                                        Perfil
                                </DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem onClick={handleClickLogout} className={styles.itensDropdown}>
                                        Sair
                                </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Nav>
                    </div>
                </Collapse>
            </Navbar>
        </div >
    );
};

export default NavbarComponent;