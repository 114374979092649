import React, { useContext } from "react";
import styles from "./NavMenuComponent.module.css";
import {
    UncontrolledDropdown,
    NavItem,
    Nav,
    NavLink,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";
import { MdOutlineDashboard, MdOutlinePeople } from "react-icons/md";
import { FaRegClipboard } from "react-icons/fa";
import { GiOfficeChair, GiHealing } from "react-icons/gi";
import { Context } from "../../context/authContext";

function NavMenuComponent() {
    const { userRole } = useContext(Context);

    return (
        <div className={styles.mainDiv}>
            <div>
                <Nav>
                    <NavItem>
                        <NavLink href="/home" active className={styles.navDashboard}>
                            <MdOutlineDashboard className={styles.iconDashboard} />
                            Dashboard
                        </NavLink>
                    </NavItem>

                    <UncontrolledDropdown inNavbar nav>
                        <DropdownToggle caret nav className={styles.navVida}>
                            <MdOutlinePeople className={styles.iconVida} />
                            Vida
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem href="/vidas" className={styles.itensDropdown}>
                                Listar
                            </DropdownItem>
                            <DropdownItem href="/cadastroVida" className={styles.itensDropdown}>
                                Cadastrar
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>

                    {(userRole === "admin" || userRole === "parceiro") && (
                        <UncontrolledDropdown inNavbar nav>
                            <DropdownToggle caret nav className={styles.navAuxiliar}>
                                <GiOfficeChair className={styles.iconAuxiliar} />
                                Auxiliar
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem href="/auxiliares" className={styles.itensDropdown}>
                                    Listar
                                </DropdownItem>
                                <DropdownItem href="/cadastroAuxiliar" className={styles.itensDropdown}>
                                    Cadastrar
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    )}

                    {userRole === "admin" && (
                        <UncontrolledDropdown inNavbar nav>
                            <DropdownToggle caret nav className={styles.navParceiro}>
                                <GiHealing className={styles.iconParceiro} />
                                Parceiro
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem href="/parceiros" className={styles.itensDropdown}>
                                    Listar
                                </DropdownItem>
                                <DropdownItem href="/cadastroParceiro" className={styles.itensDropdown}>
                                    Cadastrar
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    )}

                    <NavItem>
                        <NavLink href="/questionarios" className={styles.navListaQuestionario}>
                            <FaRegClipboard className={styles.iconQuestionario} />
                            Questionários
                        </NavLink>
                    </NavItem>
                </Nav>
            </div>
        </div>
    );
}

export default NavMenuComponent;
