import React, { useState, useEffect } from 'react';
import { useParams } from "react-router";
import NavbarComponent from '../../components/Navbar/NavbarComponent';
import NavMenuComponent from '../../components/NavMenu/NavMenuComponent';
import styles from './index.module.css';
import { ListGroup, ListGroupItem } from 'reactstrap';
import api from '../../service/api';

function VisualizarQuestionario() {
    const { id } = useParams();
    const [questionario, setQuestionario] = useState([]);
    const [nomeQuestionario, setNomeQuestionario] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        api.get('questionarios/' + id)
            .then(({ data }) => {
                console.log("Dados recebidos:", data);
                const perguntasDoQuestionario = data.filter(
                    (item) => String(item.questionario_id) === String(id)
                );

                if (perguntasDoQuestionario.length > 0) {
                    setNomeQuestionario(perguntasDoQuestionario[0].questionario_nome);
                } else {
                    console.warn("Nenhuma pergunta encontrada para o questionário:", id);
                }

                setQuestionario(perguntasDoQuestionario);
            })
            .catch((error) => {
                console.error("Erro ao buscar questionário:", error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [id]);

    if (isLoading) {
        return (
            <div className={styles.mainDiv}>
                <NavbarComponent />
                <NavMenuComponent />
                <div className={styles.title}>Carregando questionário...</div>
            </div>
        );
    }

    return (
        <div className={styles.mainDiv}>
            <NavbarComponent />
            <NavMenuComponent />

            <div className={styles.route}>
                Home &gt; Lista de Questionário &gt; Questionário
            </div>
            <div className={styles.title}>
                Questionário: {nomeQuestionario || 'Sem nome'}
            </div>
            <div className={styles.container}>
                {questionario.length > 0 ? (
                    <ListGroup flush numbered>
                        {questionario.map((pergunta, index) => (
                            <ListGroupItem key={index} className={styles.listItem}>
                                {pergunta.pergunta_texto}
                            </ListGroupItem>
                        ))}
                    </ListGroup>
                ) : (
                    <p>Não há perguntas disponíveis para este questionário.</p>
                )}
            </div>
        </div>
    );
}

export default VisualizarQuestionario;
